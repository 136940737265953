
import Vue from "vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import ATextField from "@/components/elements/a-text-field.vue";
import ICostCenter from "@/lib/interfaces/cost-center";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import VForm from "@/lib/types/v-form";
import ABtn from "@/components/elements/a-btn.vue";
import { required } from "@/lib/validations";
import ADate from "@/lib/a-date";
import ASwitch from "@/components/elements/a-switch.vue";
import IAccountPlan from "@/lib/interfaces/account-plan";

export default Vue.extend({
  name: "Reports",

  components: { ATextField, AAutocomplete, ABtn, ASwitch },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      valid: false,

      costCenters: new Array<ICostCenter>(),
      plano_contas: new Array<IAccountPlan>(),

      dataQuery: {
        plano_conta_id: [],
        centro_custo_id: [],
        data_inicial: new ADate().dateString,
        data_final: new ADate().dateString,
        detalhamento: "0",
      },
    };
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    query(): {
      plano_conta_id: string;
      centro_custo_id: string;
      data_inicial: string;
      data_final: string;
      detalhamento: string;
    } {
      return {
        ...this.dataQuery,
        plano_conta_id: this.dataQuery.plano_conta_id.join(", ") || "-1",
        centro_custo_id: this.dataQuery.centro_custo_id.join(", ") || "-1",
      };
    },

    hintCentroCusto(): string | undefined {
      return this.dataQuery.centro_custo_id.length > 0 ? undefined : "TODOS";
    },
    hintPlanosConta(): string | undefined {
      return this.dataQuery.plano_conta_id.length > 0 ? undefined : "TODOS";
    },
  },

  mounted() {
    this.getCostCenters().then();
    this.getPlanoContas().then();
  },

  methods: {
    required,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const { plano_conta_id, centro_custo_id, data_inicial, data_final } =
          this.query;
        const query = new URLSearchParams();
        query.append("plano_conta_id", plano_conta_id);
        query.append("centro_custo_id", centro_custo_id);
        query.append("data_inicial", data_inicial);
        query.append("data_final", data_final);

        Api.openWindow(`/centro-de-custo/historico?${query.toString()}`);

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getCostCenters() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("centro_de_custo.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.costCenters = rsp.getTable("centro_custo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPlanoContas() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_de_conta.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.plano_contas = rsp.getTable("plano_contas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
